<template>
  <div>

    <Row
      :gutter="8"
      class="m-b-10"
    >

      <i-col span="4"  class="m-b-5">
        <i-input
          v-model="query.keyword"
          size="small"
          placeholder="关键字：画面描述"
        ></i-input>
      </i-col>
      <i-col span="8">
        <Button
          type="primary"
          icon="ios-search"
          size="small"
          class="m-r-5 "
          @click="handleSearch"
        >搜索</Button>
        <Button
          type="success"
          size="small"
          class="m-r-5"
          @click="handleCreate"
        >新增</Button>
      </i-col>
    </Row>

    <Table stripe size="small" :max-height="680" :data="list" :columns="tableColumns" :loading="tableLoading">
    </Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator show-sizer
        :current="query.pageNumber" @on-change="handleChangePage" :page-size-opts="pagesizeSetting"
        @on-page-size-change='changePageSize'></Page>
    </div>
    <BannerCreateModal ref="BannerCreateModal" @on-success="getTableData"></BannerCreateModal>

    <Modal v-model="imgShowModal" width="1000" footer-hide>
      <p slot="header" style="text-align: center">
        {{ currentTitle }}
      </p>
      <div class="text-center">
        <img
          class="bigImg"
          style="width: 960px"
          :src="imageUrl + '?x-oss-process=image/resize,w_760'"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import BannerCreateModal from './BannerCreate'
import { getFilePage, updateStatus } from '@/api/msp/banner'

export default {
  components: {
    BannerCreateModal
  },
  data () {
    return {
      pagesizeSetting: [15, 50, 100, 200, 500],
      query: {
        pageNumber: 1,
        pageSize: 15,
        publisherId: undefined,
        openId: undefined
      },
      list: [],
      total: 0,
      tableLoading: false,
      tableColumns: [
        { title: '画面描述', align: 'center', key: 'remark' },
        {
          title: '画面预览',
          align: 'center',
          key: 'tel',
          render: (h, params) => {
            const that = this
            if (params.row.filePath) {
              return h('div', [
                h('img', {
                  domProps: {
                    src:
                      params.row.filePath + '?x-oss-process=image/resize,w_64'
                  },
                  on: {
                    click: () => {
                      that.currentTitle = params.row.remark
                      that.imageUrl = params.row.filePath
                      that.imgShowModal = true
                    }
                  },
                  style: {
                    width: '64px',
                    cursor: 'pointer'
                  }
                })
              ])
            } else {
              return h('span', '暂无素材')
            }
          }
        },

        { title: '上传时间', align: 'center', ellipsis: true, tooltip: true, key: 'createTime' },
        { title: '状态', align: 'center', ellipsis: true, tooltip: true, key: 'statusName' },
        {
          title: '操作',
          align: 'center',
          render: (h, { row }) => {
            const html = []

            // if (row.status === 0) {
            //   html.push(
            //     h('a', {
            //       class: ['m-r-5'],
            //       on: {
            //         click: () => {
            //           this.onUnmount(row.id, 1)
            //         }
            //       }
            //     }, '上架')
            //   )
            // }

            if (row.status === 1) {
              html.push(h('a', {
                class: ['m-r-5'],
                on: {
                  click: () => {
                    this.onUnmount(row.id, 2)
                  }
                }
              }, '启用'))
            } else if (row.status === 2) {
              html.push(
                h('a', {
                  class: ['m-r-5'],
                  on: {
                    click: () => {
                      this.onUnmount(row.id, 1)
                    }
                  }
                }, '停用')
              )
            }
            html.push(
              h('a', {
                class: ['m-r-5'],
                style: {
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    this.onUnmount(row.id, 3)
                  }
                }
              }, '删除')
            )
            return h('div', html)
          }
        }
      ],
      imgShowModal: false,
      currentTitle: '',
      imageUrl: ''

    }
  },
  computed: {
    userInfo () {
      return this.$store.getters.token.userInfo
    }
  },
  created () {
    this.getTableData()
  },
  methods: {
    getTableData () {
      this.tableLoading = true
      getFilePage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => { this.tableLoading = false })
    },
    handleChangePage (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    changePageSize (pageSize) {
      this.query.pageSize = pageSize
      this.query.pageNumber = 1
      this.getTableData()
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },
    handleCreate () {
      this.$refs.BannerCreateModal.showModal()
    },
    /**
     * 启动停用
     * @param {Number} status
     */
    onUnmount (id, status) {
      var operateName = '启用'
      switch (status) {
        case 1:
          operateName = '停用'
          break
        case 2:
          operateName = '启用'
          break
        case 3:
          operateName = '删除'
          break
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: `确定要${operateName}此BANNER？`,
        onOk: () => {
          updateStatus({ materialLibraryFileId: id, status: status }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.getTableData()
            }
          })
        }
      })
    }
  }
}
</script>
